import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  hamburgerStateCallback = () => {
    this.state.active
      ? this.setState({
          navBarActiveClass: 'is-active',
        })
      : this.setState({
          navBarActiveClass: '',
        })
  }

  toggleHamburger = () => {
    this.setState(
      {
        active: !this.state.active,
      },
      this.hamburgerStateCallback
    )
  }

  closeHamburgerMenu = () => {
    if (this.state.active) {
      this.setState(
        {
          active: false,
        },
        this.hamburgerStateCallback
      )
    }
  }

  render() {
    return (
      <nav
        className='navbar is-brown is-fixed-top'
        role='navigation'
        aria-label='main-navigation'
      >
        <div className='container'>
          <div className='navbar-brand'>
            <AnchorLink
              to='/#top'
              title='Logo'
              className='navbar-item stripped'
              stripHash
            >
              <h1 className='title is-4 ml-2 has-text-white'>
                {this.props.siteMetadata.title}
              </h1>
            </AnchorLink>
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target='navMenu'
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id='navMenu'
            className={`navbar-menu is-brown ${this.state.navBarActiveClass}`}
          >
            <div className='navbar-start has-text-centered'>
              <AnchorLink
                to='/#the-work'
                title='The Work'
                className='navbar-item stripped animated-underline'
                onAnchorLinkClick={() => this.closeHamburgerMenu()}
                stripHash
              />
              <AnchorLink
                to='/#offerings'
                title='Offerings'
                className='navbar-item stripped animated-underline'
                onAnchorLinkClick={() => this.closeHamburgerMenu()}
                stripHash
              />
              <AnchorLink
                to='/#about'
                title='About'
                className='navbar-item stripped animated-underline'
                onAnchorLinkClick={() => this.closeHamburgerMenu()}
                stripHash
              />
              <AnchorLink
                to='/#details'
                title='Details'
                className='navbar-item stripped animated-underline'
                onAnchorLinkClick={() => this.closeHamburgerMenu()}
                stripHash
              />
            </div>
            <div className='navbar-end has-text-centered'>
              <AnchorLink
                to='/#contact'
                title='Contact'
                className='navbar-item stripped animated-underline'
                onAnchorLinkClick={() => this.closeHamburgerMenu()}
                stripHash
              />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <Navbar siteMetadata={data.site.siteMetadata} />}
  />
)
