import React from 'react'
import {Helmet} from 'react-helmet'
import './all.scss'
import useSiteMetadata from './SiteMetadata'
import {withPrefix} from 'gatsby'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const TemplateWrapper = ({children}) => {
  const {title, description} = useSiteMetadata()
  return (
    <div>
      <Helmet>
        <html lang='en' className='has-navbar-fixed-top' />
        <title>{title}</title>
        <meta name='description' content={description} />
        {process.env?.GATSBY_ENV === 'production' && (
          <script
            src='https://cdn.counter.dev/script.js'
            data-id='64eafce1-c0e3-4dc4-ad88-036091ce6929'
            data-utcoffset='-5'
          ></script>
        )}
        <link rel='icon' href={`${withPrefix('/')}img/favicon.ico`} />
        <meta name='theme-color' content='#fff' />

        <meta property='og:type' content='business.business' />
        <meta property='og:title' content={title} />
        <meta property='og:url' content='/' />
        <meta
          property='og:image'
          content={`${withPrefix('/')}img/og-image.webp`}
        />
      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
