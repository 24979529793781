import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <div className='content has-text-centered pt-5'>
          <Link to='/' title='Logo'>
            <h1 className='title'>Inner Radiance, LLC</h1>
          </Link>
          <p className='mt-6'>
            Designed and built by{' '}
            <a
              className='attribution-link'
              href='https://www.kellenbusbysoftware.com'
              target='_blank'
              rel='noreferrer'
            >
              Kellen Busby Software
            </a>
          </p>
        </div>
      </footer>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => <Footer siteMetadata={data.site.siteMetadata} />}
  />
)
